import Vue from 'vue';
import VueGtag, { GtagEvent } from 'vue-gtag';
import { VueRouter } from "vue-router/types/router";

export type AnalyticsEvent = GtagEvent;

const gtmId = process.env.VUE_APP_BRAND_GTM_ID;

let analyticsInitialized = false;

export const EVENTS_NAME = {
  REGISTER: 'registro',
  REGISTER_APP: 'registro_app',
  DEPOSIT: 'deposit',
  FTD: 'ftd',
  CONTACT: 'contact_support',
};

export const isAnalyticsInitialized = (): boolean => analyticsInitialized;

export const initAnalytics = (router: VueRouter): void => {
  if (gtmId && !isAnalyticsInitialized()) {
    Vue.use(VueGtag, {
      config: {
        id: gtmId
      }
    }, router);

    analyticsInitialized = true;
  }
};
