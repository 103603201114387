
  import Vue, {PropType} from "vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import {REDSYS_METHODS} from "@/utils/transactions";

  export default Vue.extend({
    name: "pwa-redsys-button",
    components: {PwaIcon},
    data() {
      return {
        redsysMethods: REDSYS_METHODS,
        paymentMethodSelected: false,
        paymentMethodsAvailable: [],
      }
    },
    props: {
      type: {
        type: String,
        required: true,
      },
      amount: {
        type: String,
        required: true,
      },
      onPaymentMethodSelected: {
        type: Function as PropType<() => void>,
        default: null,
      },
      onButtonLoaded: {
        type: Function as PropType<() => void>,
        default: null,
      },
    },
    mounted(): void {
      this.initButton();
    },
    beforeDestroy(): void {
      this.destroyButton();
    },
    computed: {
      paymentMethodIcons(): string[] {
        let icons = null;
        if(this.type === REDSYS_METHODS.BIZUM){
          icons = ['payment-methods/bizum-footer.svg'];
        }else if(this.type === REDSYS_METHODS.GOOGLE_APPLE_PAY){
          icons = ['payment-methods/google-pay.png', 'payment-methods/apple-pay.png'];
        }else{
          icons = ['payment-methods/visa.svg', 'payment-methods/mastercard.svg', 'payment-methods/american-express.svg'];
        }

        return icons;
      },
    },
    methods: {
      async initButton() {
        try {
          this.destroyButton();
          const {data} = await this.$http.transaction.initDeposit(this.type, Number(this.amount));
          this.paymentMethodsAvailable = data.data || [];
        } catch (e) {
          // Nothing to do
        } finally {
          if (this.onButtonLoaded) {
            this.onButtonLoaded();
          }
        }
      },
      destroyButton() {
        this.paymentMethodsAvailable = [];
      },
      onPaymentMethodClick() {
        this.paymentMethodSelected = true;
        if (this.onPaymentMethodSelected) {
          this.onPaymentMethodSelected();
        }
      },
    },
    watch: {
      amount() {
        this.initButton();
      }
    }
  });
