
  import Vue, {PropType} from "vue";
  import {
    createOrder,
    getPaypalButton,
    onPurchaseApprove,
    OrderDataType,
    PurchaseOptions
  } from "@/services/payments/paypal";
  import {PayPalButtonsComponent} from "@paypal/paypal-js/types/components/buttons";

  export default Vue.extend({
    name: "pwa-paypal-button",
    data() {
      return {
        button: null as PayPalButtonsComponent,
        loading: false,
      }
    },
    props: {
      amount: {
        type: String,
        required: true,
      },
      currency: {
        type: String,
        required: true,
      },
      onApprove: {
        type: Function as PropType<(orderData: OrderDataType) => void>,
        required: true,
      },
      description: {
        type: String,
        default: '',
      },
      buttonStyle: {
        type: Object,
        default: () => ({
          shape: 'rect',
          color: 'white',
          layout: 'horizontal',
          label: 'paypal',
          tagline: false,
        }),
      },
      onError: {
        type: Function as PropType<(error: any) => void>,
        default: null,
      },
      onClick: {
        type: Function as PropType<(data: any) => void>,
        default: null,
      },
      onCancel: {
        type: Function as PropType<(data: any) => void>,
        default: null,
      },
      onButtonLoaded: {
        type: Function as PropType<() => void>,
        default: null,
      },
    },
    mounted(): void {
      this.initButton();
    },
    beforeDestroy(): void {
      this.destroyButton();
    },
    methods: {
      async initButton() {
        try {
          this.destroyButton();
          this.button = await getPaypalButton(this.currency, {
            style: this.buttonStyle,
            createOrder: createOrder(this.getPurchaseOptions(), this.onErrorCallback),
            onApprove: onPurchaseApprove(this.onApproveCallback, this.onErrorCallback),
            onError: this.onErrorCallback,
            onCancel: this.onCancelCallback,
            onClick: this.onClickCallback,
          });
          const paypalButtonContainer: HTMLElement = this.$refs.paypalButtonContainer as HTMLElement;
          await this.button.render(paypalButtonContainer);
        } catch (e) {
          // Nothing to do
        } finally {
          if (this.onButtonLoaded) {
            this.onButtonLoaded();
          }
        }
      },
      destroyButton() {
        if (this.button) {
          this.button.close();
          this.button = null;
        }
      },
      getPurchaseOptions(): PurchaseOptions {
        return {
          amount: {
            currency_code: this.currency,
            value: this.amount.toString(),
          },
          description: this.description,
          custom_id: this.userId.toString(),
        };
      },
      onApproveCallback(orderData: OrderDataType) {
        this.onApprove(orderData);
      },
      onErrorCallback(error: any) {
        if (this.onError) {
          this.onError(error);
        }
      },
      onClickCallback(data: any) {
        if (this.onClick) {
          this.onClick(data);
        }
      },
      onCancelCallback(data: any) {
        if (this.onCancel) {
          this.onCancel(data);
        }
      },
    },
    watch: {
      amount() {
        this.initButton();
      },
      currency() {
        this.initButton();
      }
    }
  });
