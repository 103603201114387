
  import Vue, {PropType, VNode} from "vue";

  export default Vue.extend({
    name: "pwa-stepper",
    data() {
      return {
        currentStep: 1,
      };
    },
    props: {
      canGoForward: {
        type: Boolean,
        required: false,
        default: false,
      },
      canGoBackward: {
        type: Boolean,
        required: false,
        default: true,
      },
      onChangeStep: {
        type: Function as PropType<(step: number) => void>,
        required: false,
        default: () => {/** Nothing to do */
        },
      },
      type: {
        type: String as PropType<"horizontal" | "vertical" | null>,
        required: false,
        default: null,
      },
    },
    computed: {
      steps(): VNode[] {
        const steps = [];
        for (let i = 1; i <= this.numSteps; i++) {
          if (this.$slots[`step_${i}`]) {
            steps.push(this.$slots[`step_${i}`][0]);
          }
        }

        return steps;
      },
      numSteps(): number {
        return Object.keys(this.$slots).length;
      },
      labelIds(): string[] {
        return this.steps.map(step => {
          const props = step.componentOptions.propsData as any;
          return props.labelId || '';
        });
      },
      isHorizontal(): boolean {
        return this.type === 'horizontal' || (this.type === null && this.isDesktop);
      },
    },
    methods: {
      isValidStep(step: number) {
        return step >= 1 && step <= this.numSteps;
      },
      canGoToStep(step: number) {
        const validStep = this.isValidStep(step);
        return validStep && ((step < this.currentStep && this.canGoBackward) || (this.canGoForward && step > this.currentStep));
      },
      onChangeStepClick(step: number) {
        if (this.canGoToStep(step)) {
          this.currentStep = step;
          if (this.onChangeStep) {
            this.onChangeStep(step);
          }
        }
      },
      setStep(step: number) {
        const validStep = this.isValidStep(step);
        if (validStep) {
          this.currentStep = step;
          if (this.onChangeStep) {
            this.onChangeStep(this.currentStep);
          }
        }
      },
      nextStep() {
        if (this.currentStep < this.numSteps) {
          this.currentStep = this.currentStep + 1;
          if (this.onChangeStep) {
            this.onChangeStep(this.currentStep);
          }
        }
      },
      backStep() {
        if (this.currentStep > 1) {
          this.currentStep = this.currentStep - 1;
          if (this.onChangeStep) {
            this.onChangeStep(this.currentStep);
          }
        }
      },
    },
  });
