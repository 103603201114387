import { loadScript, PayPalNamespace } from "@paypal/paypal-js";
import {
  CreateOrderActions,
  OnApproveActions,
  OnApproveData,
  PayPalButtonsComponent,
  PayPalButtonsComponentOptions,
  UnknownObject
} from "@paypal/paypal-js/types/components/buttons";
import { OrderResponseBody, PurchaseUnit } from "@paypal/paypal-js/types/apis/orders";

const paypalScripts: Record<string, PayPalNamespace> = {};

export const getPaypalScript = (currency: string): Promise<PayPalNamespace> => {
  return new Promise<PayPalNamespace>((resolve, reject) => {
    if (paypalScripts[currency]) {
      resolve(paypalScripts[currency]);
    } else {
      loadScript({"client-id": process.env.VUE_APP_PAYPAL_CLIENT_ID, currency}).then(paypal => {
        if (paypal !== null) {
          paypalScripts[currency] = paypal;
          resolve(paypalScripts[currency]);
        } else {
          reject();
        }
      }).catch(() => {
        reject();
      });
    }
  });
};

export const getPaypalButton = (currency: string, buttonOptions: PayPalButtonsComponentOptions): Promise<PayPalButtonsComponent> => {
  return new Promise<PayPalButtonsComponent>((resolve, reject) => {
    getPaypalScript(currency).then(paypal => {
      if (paypal.Buttons) {
        resolve(paypal.Buttons(buttonOptions));
      } else {
        reject();
      }
    }).catch(() => {
      reject();
    })
  });
};

export type PurchaseOptions = PurchaseUnit;

export const createOrder = (purchaseOptions: PurchaseUnit, onError?: (error: unknown) => void): (data: UnknownObject, actions: CreateOrderActions) => Promise<any> => {
  return (data: UnknownObject, actions: CreateOrderActions) => {
    return actions.order.create({
      purchase_units: [purchaseOptions],
      application_context: {
        brand_name: process.env.VUE_APP_NAME,
        shipping_preference: 'NO_SHIPPING',
      },
    }).catch(error => {
      if (onError) {
        onError(error);
      }
    });
  }
};

export type OrderDataType = OrderResponseBody;

export const onPurchaseApprove = (onSuccess: (orderData: OrderDataType) => void, onError?: (error: any) => void): (data: OnApproveData, actions: OnApproveActions) => Promise<void> => {
  return (data: OnApproveData, actions: OnApproveActions) => {
    return actions.order.capture().then(onSuccess).catch(error => {
      if (onError) {
        onError(error);
      }
    });
  }
};
