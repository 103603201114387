
  import Vue from 'vue';
  import PwaStepper from "@/components/common/PwaStepper.vue";
  import PwaStep from "@/components/common/PwaStep.vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import PwaPrice from "@/components/common/PwaPrice.vue";
  import PwaPaypalButton from "@/components/deposit/PwaPaypalButton.vue";
  import PwaRedsysButton from "@/components/deposit/PwaRedsysButton.vue";
  import {OrderDataType} from "@/services/payments/paypal";
  import {MODAL_IDS} from "@/utils/modal";
  import {DEPOSIT_METHODS} from "@/utils/transactions";
  import {UserActionTypes} from "@/store/modules/user/actions";
  import {REDSYS_METHODS} from "@/utils/transactions";
  import {ROUTES} from "@/router/routes";
  import { EVENTS_NAME } from '@/services/analytics';
  import {EVENTS} from "@/utils/events";

  const ORDER_STATUS_COMPLETED = 'COMPLETED';

  const DEPOSIT_STATUS = {
    WAITING: 'WAITING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
  };

  const PAYMENT_METHODS = {
    CARD: REDSYS_METHODS.CARD,
    BIZUM: REDSYS_METHODS.BIZUM,
    GOOGLE_APPLE_PAY: REDSYS_METHODS.GOOGLE_APPLE_PAY,
    PAYPAL: DEPOSIT_METHODS.PAYPAL,
  };

  export default Vue.extend({
    name: "pwa-deposit",
    components: {
      PwaStepper,
      PwaStep,
      PwaPaypalButton,
      PwaRedsysButton,
      PwaIcon,
      PwaPrice,
    },
    data() {
      return {
        currentStep: 1,
        amountIndexSelected: null,
        status: DEPOSIT_STATUS.WAITING,
        depositStatus: DEPOSIT_STATUS,
        depositAmountsWithDiscounts: [],
        redsysMethods: REDSYS_METHODS,
        userMustVerifyIdentity: false,
        allowBonus: false,
        paymentMethodSelected: null,
        paymentMethods: PAYMENT_METHODS,
        buttonsLoaded: {
          [PAYMENT_METHODS.CARD]: false,
          [PAYMENT_METHODS.BIZUM]: false,
          [PAYMENT_METHODS.GOOGLE_APPLE_PAY]: false,
          [PAYMENT_METHODS.PAYPAL]: false,
        },
      }
    },
    props: {
      isModal: {
        type: Boolean,
        required: false,
        default: false,
      },
      nextModal: {
        type: String,
        required: false,
        default: ''
      }
    },
    computed: {
      stepper(): any {
        return this.$refs.stepper;
      },
      continueButtonDisabled(): boolean {
        return this.currentStep !== 3 && this.status !== DEPOSIT_STATUS.ERROR && this.status !== DEPOSIT_STATUS.SUCCESS;
      },
      nextUrl(): string {
        return this.$store.getters.nextUrl;
      },
      currency(): string {
        return this.$store.state.app.config.currency && this.$store.state.app.config.currency.isoCode
          ? this.$store.state.app.config.currency.isoCode
          : 'EUR';
      },
      Ds_MerchantParameters() : any {
        return this.$route.query.Ds_MerchantParameters;
      },
      Ds_Signature() : any {
        return this.$route.query.Ds_Signature;
      }
    },
    mounted(): void {
      this.initData();
    },
    methods: {
      async initData() {
        try {
          this.showLoader(true);
          const {data} = await this.$http.page.getWalletDepositPage();
          this.depositAmountsWithDiscounts = data.data.deposit_amounts || [];
          this.userMustVerifyIdentity = data.data.user_must_verify_identity || false;
          this.allowBonus = data.data.allow_bonus || false;
          if(this.Ds_MerchantParameters && this.Ds_Signature){
            this.stepper.nextStep();
            this.stepper.nextStep();
            this.stepper.nextStep();
            this.showLoader(false);
            await this.verifyDeposit();
          }
        } catch (e) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
      onAmountSelected(index: number) {
        this.amountIndexSelected = index;
        this.onContinueClick();
      },
      setCurrentStep(step: number) {
        if(this.currentStep == 3){
          this.onCancelClick();
        }else{
          this.currentStep = step;
          if (step === 1) {
            this.amountIndexSelected = null
            this.paymentMethodSelected = null;
            this.buttonsLoaded = {
              [PAYMENT_METHODS.CARD]: false,
              [PAYMENT_METHODS.BIZUM]: false,
              [PAYMENT_METHODS.GOOGLE_APPLE_PAY]: false,
              [PAYMENT_METHODS.PAYPAL]: false,
            };
          } else if (step === 2) {
            if (!this.buttonsLoaded[PAYMENT_METHODS.PAYPAL] || !this.buttonsLoaded[PAYMENT_METHODS.CARD] || !this.buttonsLoaded[PAYMENT_METHODS.BIZUM] || !this.buttonsLoaded[PAYMENT_METHODS.GOOGLE_APPLE_PAY]) {
              this.showLoader(true);
            }
          }
        }
      },
      async verifyDeposit(){
        let error = false;
        if (this.Ds_MerchantParameters && this.Ds_Signature) {
          try {
            const {data} = await this.$http.user.postWalletUserDeposit(DEPOSIT_METHODS.REDSYS, {Ds_MerchantParameters: this.Ds_MerchantParameters, Ds_Signature: this.Ds_Signature});
            if (data.data.success) {
              const firstDeposit = !this.$store.state.user.wallet.has_deposit;
              this.status = DEPOSIT_STATUS.SUCCESS;
              await this.$store.dispatch(UserActionTypes.UPDATE_USER_WALLET);
              this.amountIndexSelected = this.getAmountIndex(data.data.amount);
              if (!this.isDesktop && this.nextUrl) {
                await this.goToNextUrl();
              } else if (this.nextModal) {
                this.hideModalById(MODAL_IDS.DEPOSIT);
                this.showModalById(this.nextModal);
              }
              if(this.isModal){
                this.$router.replace({name: ROUTES.wallet.name, params: {lang: this.routeLang}});
              }
              this.sendAnalyticsEvent(EVENTS_NAME.DEPOSIT, {'quantity': data.data.amount});
              if(firstDeposit){
                this.sendAnalyticsEvent(EVENTS_NAME.FTD, {'quantity': data.data.amount, 'currency': this.currency});
              }
              this.$root.$emit(EVENTS.RELOAD_WALLET_PAGE);
            } else {
              error = true;
            }
          } catch (e) {
            error = true;
          }
        } else {
          error = true;
        }

        if (error) {
          this.status = DEPOSIT_STATUS.ERROR;
          if(this.isDesktop){
            this.$router.replace({name: ROUTES.wallet.name, params: {lang: this.routeLang}});
          }
        }
      },
      getAmountIndex(quantity: number){
        const depositAmount = this.depositAmountsWithDiscounts.find(depositAmount => depositAmount.amount == quantity);
        return this.depositAmountsWithDiscounts.indexOf(depositAmount);
      },
      async onPaypalSuccess(orderData: OrderDataType) {
        let error = false;
        if (orderData.status === ORDER_STATUS_COMPLETED) {
          try {
            const {data} = await this.$http.user.postWalletUserDeposit(DEPOSIT_METHODS.PAYPAL, orderData);
            if (data.data.success) {
              const firstDeposit = !this.$store.state.user.wallet.has_deposit;
              this.status = DEPOSIT_STATUS.SUCCESS;
              await this.$store.dispatch(UserActionTypes.UPDATE_USER_WALLET);
              if (!this.isDesktop && this.nextUrl) {
                await this.goToNextUrl();
              } else if (this.nextModal) {
                this.hideModalById(MODAL_IDS.DEPOSIT);
                this.showModalById(this.nextModal);
              }
              this.$router.replace({name: ROUTES.wallet.name, params: {lang: this.routeLang}});
              this.sendAnalyticsEvent(EVENTS_NAME.DEPOSIT, {'quantity': this.depositAmountsWithDiscounts[this.amountIndexSelected].amount});
              if(firstDeposit){
                this.sendAnalyticsEvent(EVENTS_NAME.FTD, {'quantity': this.depositAmountsWithDiscounts[this.amountIndexSelected].amount, 'currency': this.currency});
              }
              this.$root.$emit(EVENTS.RELOAD_WALLET_PAGE);
            } else {
              error = true;
            }
          } catch (e) {
            error = true;
          }
        } else {
          error = true;
        }

        if (error) {
          this.onPaymentMethodError();
        }
      },
      onPaymentMethodError() {
        this.status = DEPOSIT_STATUS.ERROR;
      },
      onPaymentMethodWaiting() {
        this.status = DEPOSIT_STATUS.WAITING;
        this.stepper.nextStep();
      },
      onPaymentMethodSelected(paymentMethod: string) {
        this.paymentMethodSelected = paymentMethod;
      },
      onPaymentMethodCancel() {
        this.status = DEPOSIT_STATUS.WAITING;
        this.stepper.setStep(2);
      },
      onCancelClick() {
        if(this.Ds_MerchantParameters && this.Ds_Signature){
          if (this.isModal) {
            this.hideModalById(MODAL_IDS.DEPOSIT);
            if (this.nextModal) {
              this.showModalById(this.nextModal);
            }
            this.$router.replace({name: ROUTES.wallet.name, params: {lang: this.routeLang}});
          }else{
            this.$router.replace({name: ROUTES.wallet.name, params: {lang: this.routeLang}});
          }
        }else{
          if (this.isModal) {
            this.hideModalById(MODAL_IDS.DEPOSIT);
            if (this.nextModal) {
              this.showModalById(this.nextModal);
            }
          } else {
            this.$router.back();
          }
        }
      },
      onContinueClick() {
        if (this.currentStep === 1) {
          if (this.userMustVerifyIdentity) {
            if (this.isModal) {
              this.hideModalById(MODAL_IDS.DEPOSIT);
            }
            this.showToast(this.$t('442'), this.$t('443'));
            this.$router.push({name: ROUTES.verifyIdentity.name, params: {lang: this.routeLang}});
          } else {
            this.stepper.nextStep();
          }
        } else if (this.currentStep === 3) {
          this.onCancelClick();
        }
      },
      onButtonLoaded(buttonName: string) {
        this.buttonsLoaded[buttonName] = true;
        if (this.buttonsLoaded[PAYMENT_METHODS.PAYPAL] && this.buttonsLoaded[PAYMENT_METHODS.CARD] && this.buttonsLoaded[PAYMENT_METHODS.BIZUM] && this.buttonsLoaded[PAYMENT_METHODS.GOOGLE_APPLE_PAY]) {
          this.showLoader(false);
        }
      },
    },
  });
