
  import Vue from "vue";

  export default Vue.extend({
    name: "pwa-step",
    props: {
      labelId: {
        type: String,
        required: true,
      },
    },
  });
